<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="227" height="55" viewBox="0 0 227 55" fill="none">
    <path d="M54.8603 27.8083C54.8603 32.129 53.1818 36.1832 50.1289 39.2361L35.4352 53.9475L32.9707 51.483L47.6822 36.7893C50.0757 34.3959 51.3967 31.2009 51.3967 27.8083C51.3967 24.4335 50.0757 21.2386 47.6822 18.8451L34.0231 5.18605L13.5456 25.6658C13.1349 26.0765 13.1349 26.7382 13.5456 27.1467L22.7597 36.3608L33.5258 25.5947L35.9903 28.0415L23.992 40.0398C23.3303 40.6814 22.1891 40.7014 21.5452 40.0398L16.5274 35.0397L11.0989 29.5957C10.2419 28.7386 9.77783 27.613 9.77783 26.4007C9.77783 25.204 10.2419 24.0628 11.0989 23.2235L32.7931 1.51152C33.4548 0.869859 34.596 0.869859 35.2398 1.51152L50.1312 16.4028C53.1818 19.4535 54.8603 23.5055 54.8603 27.8083Z" fill="url(#paint0_linear_380_119)"/>
    <path d="M45.2201 28.0597C45.2201 30.291 44.3631 32.3625 42.7911 33.9345L23.7213 53.0044C23.0796 53.666 21.9362 53.666 21.2745 53.0044L3.58123 35.3288C1.27882 33.0264 0.0288086 29.9536 0.0288086 26.7053C0.0288086 23.4571 1.27882 20.402 3.58123 18.0996L21.2745 0.40625L23.7035 2.87075L6.02797 20.5463C4.38497 22.1893 3.49242 24.3851 3.49242 26.7053C3.49242 29.0432 4.38497 31.2235 6.02797 32.8643L22.5068 49.3254L40.3444 31.4878C41.2547 30.5774 41.7543 29.363 41.7543 28.0597C41.7543 26.7741 41.2547 25.5419 40.3444 24.6316L33.8102 18.1151L25.3465 26.5965L26.5077 27.7577L24.0432 30.2222L20.4908 26.652L20.5085 26.6343L20.4553 26.581L32.5957 14.4406C33.2573 13.7612 34.363 13.7612 35.0424 14.4406L40.7374 20.1355L42.0584 21.4744L42.7911 22.1893C44.3631 23.7568 45.2201 25.8461 45.2201 28.0597Z" fill="url(#paint1_linear_380_119)"/>
    <path d="M70.3316 14.1367C66.626 14.1367 63.975 14.8605 62.4519 16.2882C60.9376 17.7091 60.1694 20.2003 60.1694 23.6927V30.2092C60.1694 33.7017 60.9376 36.1906 62.4519 37.6116C63.975 39.0414 66.626 39.7653 70.3316 39.7653H85.6381C87.0835 39.7653 88.1248 39.3745 88.8264 38.573C89.5325 37.7648 89.8921 36.366 89.8921 34.4144V25.5866C89.8921 25.0782 89.7123 24.8961 89.2016 24.8961H73.4022C73.1158 24.8961 73.0159 24.9605 73.0159 25.3202V28.1621C73.0159 28.5218 73.118 28.5862 73.4022 28.5862H84.5035C85.052 28.5862 85.405 28.9548 85.405 29.5254V32.5938C85.405 33.5441 85.2296 34.219 84.8677 34.6586C84.4925 35.1182 83.8841 35.3514 83.0648 35.3514H70.5603C68.471 35.3514 67.0656 34.9539 66.2641 34.1391C65.4648 33.3243 65.074 31.8789 65.074 29.7163V24.2611C65.074 22.123 65.4626 20.6887 66.2641 19.8761C67.0656 19.059 68.471 18.6616 70.5603 18.6616H89.5436C89.83 18.6616 89.9299 18.5972 89.9299 18.2376V14.5253C89.9299 14.2166 89.85 14.1367 89.5436 14.1367H70.3316Z" fill="url(#paint2_linear_380_119)"/>
    <path d="M127.679 39.5383L115.145 16.7917C114.575 15.7393 114.002 15.0421 113.413 14.6669C112.843 14.3095 112.092 14.1318 111.182 14.1318H108.933C108.2 14.1318 107.576 14.3095 107.041 14.6847C106.488 15.0599 105.916 15.7748 105.345 16.7917L92.936 39.5561C92.8827 39.6449 92.8472 39.716 92.8472 39.7515C92.8827 39.7515 92.936 39.7692 93.0248 39.7692H97.9538C98.2757 39.7692 98.3645 39.6449 98.4178 39.5361L101.167 34.3762H119.504L122.326 39.5006C122.397 39.6427 122.485 39.7692 122.807 39.7692H127.628C127.699 39.7692 127.752 39.7515 127.77 39.7515C127.75 39.716 127.732 39.6449 127.679 39.5383ZM104.575 29.9867L110.163 19.5426L109.77 19.3295L110.181 19.5249L110.449 19.5426L116.109 29.9867H104.575Z" fill="url(#paint3_linear_380_119)"/>
    <path d="M130.574 14.1367C130.212 14.1367 130.147 14.2389 130.147 14.5253V30.2114C130.147 33.7039 130.916 36.1928 132.432 37.6138C133.955 39.0437 136.606 39.7675 140.31 39.7675H151.222C154.926 39.7675 157.579 39.0437 159.102 37.6138C160.616 36.1928 161.384 33.7017 161.384 30.2114V14.5253C161.384 14.2166 161.304 14.1367 160.998 14.1367H156.868C156.558 14.1367 156.482 14.2122 156.482 14.5253V29.6431C156.482 31.8034 156.1 33.2488 155.316 34.0636C154.524 34.8829 153.125 35.2803 151.036 35.2803H140.541C138.451 35.2803 137.046 34.8829 136.244 34.0658C135.445 33.251 135.056 31.8034 135.056 29.6431V14.5253C135.056 14.2166 134.977 14.1367 134.67 14.1367H130.574Z" fill="url(#paint4_linear_380_119)"/>
    <path d="M167.251 35.3931C166.891 35.3931 166.827 35.4952 166.827 35.7794V39.3785C166.827 39.6649 166.891 39.7648 167.251 39.7648H188.241C190.333 39.7648 191.916 39.2475 192.948 38.2262C193.978 37.2048 194.502 35.795 194.502 34.0343V31.0036C194.502 29.0409 193.978 27.5223 192.946 26.4876C191.913 25.4552 190.357 24.9312 188.317 24.9312H174.107C172.548 24.9312 171.691 24.0187 171.691 22.3624V21.0746C171.691 19.4183 172.564 18.508 174.145 18.508H193.128C193.434 18.508 193.514 18.428 193.514 18.1194V14.5204C193.514 14.2118 193.434 14.1318 193.128 14.1318H173.048C170.956 14.1318 169.373 14.6492 168.341 15.6705C167.295 16.7051 166.787 18.0772 166.787 19.8623V22.6288C166.787 24.5937 167.311 26.1124 168.343 27.1448C169.375 28.1772 170.932 28.7012 172.972 28.7012H187.18C188.738 28.7012 189.595 29.6138 189.595 31.2701V32.822C189.595 34.4784 188.723 35.3909 187.14 35.3909H167.251V35.3931Z" fill="url(#paint5_linear_380_119)"/>
    <path d="M199.722 35.3931C199.36 35.3931 199.296 35.4952 199.296 35.7794V39.3785C199.296 39.6649 199.36 39.7648 199.722 39.7648H220.712C222.804 39.7648 224.387 39.2475 225.419 38.2262C226.449 37.2048 226.973 35.795 226.973 34.0343V31.0036C226.973 29.0409 226.449 27.5223 225.417 26.4876C224.385 25.4552 222.828 24.9312 220.788 24.9312H206.58C205.022 24.9312 204.165 24.0187 204.165 22.3624V21.0746C204.165 19.4183 205.037 18.508 206.618 18.508H225.601C225.908 18.508 225.988 18.428 225.988 18.1194V14.5204C225.988 14.2118 225.908 14.1318 225.601 14.1318H205.519C203.43 14.1318 201.847 14.6492 200.812 15.6705C199.766 16.7051 199.258 18.075 199.258 19.8623V22.6288C199.258 24.5937 199.782 26.1124 200.814 27.1448C201.847 28.1772 203.403 28.7012 205.443 28.7012H219.653C221.21 28.7012 222.069 29.6138 222.069 31.2701V32.822C222.069 34.4784 221.196 35.3909 219.615 35.3909H199.722V35.3931Z" fill="url(#paint6_linear_380_119)"/>
    <defs>
      <linearGradient id="paint0_linear_380_119" x1="32.3193" y1="53.1391" x2="32.3193" y2="6.9934" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint1_linear_380_119" x1="22.6243" y1="53.4984" x2="22.6243" y2="0.402766" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint2_linear_380_119" x1="75.0481" y1="43.9999" x2="75.0481" y2="17.1949" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint3_linear_380_119" x1="110.306" y1="43.9994" x2="110.306" y2="17.1944" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint4_linear_380_119" x1="145.767" y1="43.9999" x2="145.767" y2="17.1949" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint5_linear_380_119" x1="180.644" y1="43.9994" x2="180.644" y2="17.1944" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint6_linear_380_119" x1="213.114" y1="43.9994" x2="213.114" y2="17.1944" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "full-logo-svg"
}
</script>

<style scoped>

</style>
