import { createApp } from 'vue';
import VueScrollTo from 'vue-scrollto'
import VueClickAway from "vue3-click-away"
import VContainer from '@/components/ui-kits/v-container.vue'
import VImg from '@/components/ui-kits/v-img.vue'
import VIcon from '@/components/ui-kits/v-icon.vue'
import App from './App.vue';

// GLOBAL CSS
import "@/assets/scss/main.scss"

const app = createApp(App)

app
  .component('v-container', VContainer)
  .component('v-img', VImg)
  .component('v-icon', VIcon)
  .use(VueScrollTo)
  .use(VueClickAway)
  .mount('#app');
