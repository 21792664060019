<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g clip-path="url(#clip0_200_3927)">
      <path d="M39.9998 20.4619C39.9998 23.6141 38.7754 26.5708 36.5484 28.7978L25.8287 39.5304L24.0313 37.733L34.7639 27.0134C36.5091 25.2678 37.4732 22.9364 37.4732 20.4619C37.4732 18.0001 36.5095 15.6686 34.7639 13.9234L24.7995 3.95932L9.85985 18.8989C9.56022 19.1986 9.56022 19.6804 9.85985 19.9801L16.5806 26.7008L24.4347 18.8467L26.2321 20.6312L17.4795 29.3841C16.9976 29.853 16.1639 29.866 15.695 29.3841L12.035 25.7371L8.0754 21.7645C7.45022 21.1393 7.11133 20.3186 7.11133 19.433C7.11133 18.5604 7.44985 17.7267 8.0754 17.1145L23.901 1.27598C24.3828 0.807094 25.2165 0.807094 25.6854 1.27598L36.5484 12.1389C38.7754 14.3664 39.9998 17.323 39.9998 20.4619Z" fill="url(#paint0_linear_200_3927)"/>
      <path d="M32.9667 20.6447C32.9667 22.2728 32.3415 23.7836 31.1952 24.9299L17.2841 38.8406C16.8152 39.3225 15.9815 39.3225 15.4996 38.8406L2.59185 25.9458C0.911852 24.2654 0 22.0254 0 19.6547C0 17.2839 0.911852 15.0569 2.59185 13.3765L15.4996 0.46875L17.2711 2.26616L4.3763 15.161C3.17815 16.3595 2.52667 17.9613 2.52667 19.6547C2.52667 21.361 3.17778 22.9502 4.3763 24.1484L16.3985 36.1576L29.4107 23.1454C30.0752 22.481 30.4396 21.5954 30.4396 20.6447C30.4396 19.7069 30.0748 18.808 29.4107 18.1439L24.6433 13.3895L18.4696 19.5765L19.3163 20.4232L17.5189 22.2206L14.9267 19.6154L14.9396 19.6025L14.9007 19.5632L23.7578 10.7062C24.2396 10.2113 25.0474 10.2113 25.5422 10.7062L29.6974 14.8613L30.6611 15.8384L31.1952 16.3595C32.3411 17.5054 32.9667 19.0295 32.9667 20.6447Z" fill="url(#paint1_linear_200_3927)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_200_3927" x1="23.5557" y1="38.9401" x2="23.5557" y2="5.27676" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <linearGradient id="paint1_linear_200_3927" x1="16.4834" y1="38.6098" x2="16.4834" y2="4.83552" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2C00"/>
        <stop offset="1" stop-color="#FE8F00"/>
      </linearGradient>
      <clipPath id="clip0_200_3927">
        <rect width="40" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "logo-svg"
}
</script>

<style scoped>

</style>
